<template>

  <!-- #region::Transfer pieces modal -->
  <b-modal
    id="update-piece-modal"
    ref="update-piece-modal"
    title="Editar pieza seleccionada"
    centered
    size="xl"
    @hidden="onCloseModal"
  >
    <b-card-text>
      <b-row>
        <b-col
          cols="12"
        >
          <b-alert
            show
            variant="info"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="AlertCircleIcon"
              />
              <span class="ml-25">Las cotizaciones deben tener productos y piezas de la misma ubicación.</span>
            </div>
          </b-alert>
        </b-col>

        <!-- #region begin::Products list -->
        <b-col>

          <b-skeleton
            v-if="isLoadingProducts"
            animation="wave"
            width="100%"
            height="500px"
          />

          <!-- #region begin::Collapsable products list -->
          <app-collapse
            v-else
            accordion
            type="margin"
          >
            <ProductCollapsableItem
              v-if="product"
              :key="product.IdProduct"
              :product="product"
              :is-visible="true"
              :show-pieces-list="true"
              :origin-product-id="product.IdProduct"
              :selectable-pieces-list="true"
            />
          </app-collapse>
          <!-- #endregion end::Collapsable products list -->
        </b-col>
        <!-- #endregion end::Products list -->
      </b-row>
    </b-card-text>

    <!-- #region::Footer -->
    <template #modal-footer>
      <b-button
        variant="delete-btn"
        class="delete-btn"
        @click="hideModal"
      >
        Cancelar
      </b-button>
      <b-button
        variant="principal-btn"
        class="principal-btn"
        @click="onUpdatePiece"
      >
        Actualizar piezas
      </b-button>
    </template>
    <!-- #endregion::Footer -->

  </b-modal>
  <!-- #endregion::Transfer pieces modal -->

</template>

<script>
// #region Imports
import { mapGetters, mapActions, mapState } from 'vuex'
import {
  BRow, BCol, BModal, VBModal, BCardText, BButton, BAlert, BSkeleton,
} from 'bootstrap-vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BModal,
    BButton,
    BCardText,
    BSkeleton,
    AppCollapse,
    ProductCollapsableItem: () => import('@/modules/production/products/components/ProductCollapsableItem.vue'),
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    productId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      pieceData: '',
    }
  },
  computed: {
    ...mapState('products', ['product', 'isLoadingProducts']),
    ...mapGetters({
      getDetailProducts: 'orders/getDetailProducts',
      getProvitionalPieces: 'circuits/getProvitionalPieces',
      getProvitionalProducts: 'circuits/getProvitionalProducts',
    }),
    provitionalPieces: {
      get() { return this.getProvitionalPieces },
    },
    provitionalProducts: {
      get() { return this.getProvitionalProducts },
    },
    orderProducts: {
      get() { return this.getDetailProducts },
    },
    provitionalProduct() {
      return this.provitionalProducts.find(provitionalProduct => provitionalProduct.IdProduct === this.product.IdProduct)
    },
    orderProduct() {
      return this.orderProducts.find(orderProduct => orderProduct.IdProduct === this.product.IdProduct)
    },
  },
  created() {
    // this.loadProduct({ id: this.productId })
  },
  methods: {
    ...mapActions({
      setProduct: 'products/setProduct',
      loadProduct: 'products/loadProduct',
      clearFormData: 'circuits/clearFormData',
      updateDetailProduct: 'orders/updateDetailProduct',
    }),
    showModal(pieceData) {
      this.pieceData = pieceData
      this.loadProduct({ id: this.productId, withPieces: true })
      this.$refs['update-piece-modal'].show()
    },
    hideModal() {
      this.$refs['update-piece-modal'].hide()
    },
    onUpdatePiece() {
      if (this.provitionalPieces.length > 0) {
        // 03/04/2023 - TAG: Can not select more than one piece for changing
        if (this.provitionalPieces.length > 1) {
          this.showToast(
            'Error de validación',
            'Solo se permite seleccionar una pieza para editar.',
            'warning',
          )
          return
        }

        const isInTransfer = piece => piece.Transfer === 'Si'
        const isAvailable = piece => piece.Availability !== 'No disponible'
        const isSamePiece = piece => piece.IdPiece === this.pieceData.IdPiece
        const isAddedPiece = piece => piece.IdPiece === this.provitionalPieces[0].IdPiece
        const isSameLocation = piece => piece.Location === this.pieceData.Location

        const sameLocationProduct = this.provitionalPieces.every(isSameLocation)
        const available = this.provitionalPieces.every(isAvailable)
        const inTransfer = this.provitionalPieces.some(isInTransfer)
        const samePiece = this.provitionalPieces.some(isSamePiece)
        const addedPiece = this.orderProduct.pieces.some(isAddedPiece)

        if (!sameLocationProduct) {
          this.showToast(
            'Error de validación',
            'La pieza seleccionada no pertenece a la misma ubicación.',
            'warning',
          )
          return
        }

        if (!available) {
          this.showToast(
            'Error de validación',
            'La pieza seleccionada no está disponible.',
            'warning',
          )
          return
        }

        if (inTransfer) {
          this.showToast(
            'Error de validación',
            'Una o más piezas seleccionadas se encuentran en una orden de traspaso.',
            'warning',
          )
          return
        }

        if (samePiece) {
          this.showToast(
            'Error de validación',
            'La pieza seleccionada ya ha sido agregada.',
            'warning',
          )
          return
        }

        if (addedPiece) {
          this.showToast(
            'Error de validación',
            'La pieza seleccionada ya ha sido agregada.',
            'warning',
          )
          return
        }

        const filteredPieces = this.orderProduct.pieces.filter(piece => piece.IdPiece !== this.pieceData.IdPiece)

        this.orderProduct.pieces = [...this.provitionalProduct.pieces.map(piece => ({ ...piece })), ...filteredPieces]
        this.updateDetailProduct(this.orderProduct)
        this.hideModal()
      } else {
        this.showToast(
          'Error de validación',
          'No se ha seleccionado ninguna pieza.',
          'warning',
        )
      }
    },
    onCloseModal() {
      this.clearFormData()
      this.setProduct(null)
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
